import { useFloating, FloatingPortal, useDismiss, FloatingFocusManager, useInteractions, FloatingOverlay } from '@floating-ui/react'

import { useOnShowSkillsMatchPortalFlow } from '/features/pageOnly/skillsMatch/skillsMatchPortalFlowHooks'
import { SkillsMatchApp } from '/features/pageOnly/skillsMatch/SkillsMatchApp'
import { trackInteraction } from '/analytics/user-interaction'

import styles from './SkillsMatchPortalFlow.css'

export function SkillsMatchPortalFlow({ layoutClassName }) {
  const [showSkillsMatchPortalFlow, setShowSkillsMatchPortalFlow] = React.useState(false)
  useOnShowSkillsMatchPortalFlow(() => setShowSkillsMatchPortalFlow(true))

  return (
    <div className={layoutClassName}>
      <SkillsMatchPortal isOpen={showSkillsMatchPortalFlow} onClose={handleClose} />
    </div>
  )

  function handleClose() {
    setShowSkillsMatchPortalFlow(false)
    trackInteraction({
      category: 'skillsmatch-portal',
      type: 'click',
      action: 'skillsmatch portal closed'
    })
  }
}

function SkillsMatchPortal({ isOpen, onClose }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([ dismiss ])

  return (
    isOpen && (
      <FloatingPortal>
        <FloatingFocusManager {...{ context }} modal>
          <FloatingOverlay
            // eslint-disable-next-line @kaliber/layout-class-name
            className={styles.componentSkillsMatchPortal}
            lockScroll={isOpen}
            {...getReferenceProps()}
          >
            <div ref={refs.setFloating} aria-hidden={!isOpen} className={styles.skillsMatchWrapper} {...getFloatingProps()}>
              <div className={styles.skillsMatchContainer}>
                <SkillsMatchApp layoutClassName={styles.skillsMatchLayout} {...{ onClose }} />
              </div>
            </div>
          </FloatingOverlay>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  )
}
