import { useMySelectionMutations } from '/features/pageOnly/skillsMatch/buildingBlocks/MySelection'
import { SkillsMatchButtonToggle } from '/features/pageOnly/skillsMatch/buildingBlocks/SkillsMatchButton'

export function ListItemButton({ id, label, isSelected }) {
  const { toggleUserSelection, isMutating } = useMySelectionMutations()

  return (
    <SkillsMatchButtonToggle
      onClick={_ => handleOnClick(id)}
      isActive={isSelected}
      isProcessing={isMutating}
      {...{ id, label }}
    />
  )

  function handleOnClick(id) {
    toggleUserSelection({ type: 'skill', id })
  }
}
