import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './CloseButton.css'

import closeIcon from '/images/icons/close-light.raw.svg'

export function CloseButton({ onClick, layoutClassName }) {
  return <CloseButtonBase className={styles.component} {...{ onClick, layoutClassName }} />
}

export function CloseButtonGray({ onClick, layoutClassName }) {
  return <CloseButtonBase className={styles.componentGray} {...{ onClick, layoutClassName }} />
}

export function CloseButtonWhite({ onClick, layoutClassName }) {
  return <CloseButtonBase className={styles.componentWhite} {...{ onClick, layoutClassName }} />
}

function CloseButtonBase({ onClick, layoutClassName, className }) {
  const { __ } = useTranslate()

  return (
    <button data-x='close-skills-match' aria-label={__`close-overlay`} type='button' className={cx(styles.componentBase, layoutClassName, className)} {...{ onClick }}>
      <Icon icon={closeIcon} layoutClassName={styles.closeIconLayout} />
    </button>
  )
}
