import { useSpring, animated } from 'react-spring'
import { useTranslate } from '/machinery/I18n'
import { useVideoData } from './interactiveVideoData'
import { useMediaQuery } from '@kaliber/use-media-query'
import { InteractiveVideo } from '/features/pageOnly/skillsMatch/buildingBlocks/InteractiveVideo'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { useMutation } from '@tanstack/react-query'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'

import mediaStyles from '/cssGlobal/media.css'
import arrowIcon from '/images/icons/arrow.raw.svg'
import styles from './InteractiveVideoTab.css'

export function InteractiveVideoTab({ goToNextStep }) {
  const reportError = useReportError()
  const isMaxMd = useMediaQuery(mediaStyles.viewportMaxMd)
  const [hasInteracted, setHasInteracted] = React.useState(false)
  const { mutate: markIntroductionSeen } = useMutation({
    mutationFn: handleMarkIntroductionSeen,
    onError: reportError,
    onSettled: goToNextStep,
  })

  const videoSpring = useSpring({
    width: hasInteracted && isMaxMd ? '90%' : '50%',
  })

  return (
    <div className={styles.component}>
      <div className={styles.grid}>
        <InteractiveVideoFlow
          onVideoFlowFinished={handleOnVideoFlowFinished}
          onHasInteracted={setHasInteracted}
          animation={videoSpring}
          layoutClassName={styles.videoFlowLayout}
        />
        <SkillsCTA onClickCtaButton={handleOnVideoFlowFinished} layoutClassName={styles.ctaLayout} />
      </div>
    </div>
  )

  function handleOnVideoFlowFinished() {
    markIntroductionSeen()
  }
}

function InteractiveVideoFlow({ onVideoFlowFinished, onHasInteracted, layoutClassName, animation }) {
  const [currentVideoState, setCurrentVideoState] = React.useState(null)
  const [isVideoEnding, setIsVideoEnding] = React.useState(false)
  const { NEXT_STEP_ACTIONS, data } = useVideoData()
  const { title, video, questions } = data[currentVideoState] ?? data.opening

  return (
    <animated.div className={cx(styles.componentInteractiveVideoFlow, layoutClassName)} style={animation}>
      <InteractiveVideo
        importedPoster={video.poster}
        url={video.src}
        onIsVideoEnding={handleIsVideoEnding}
        layoutClassName={styles.videoLayout}
        hasFollowUpQuestions={Boolean(questions?.length)}
        {...{ title, onHasInteracted }}
      >
        {Boolean(questions?.length) && isVideoEnding && (
          <div className={styles.questions}>
            {questions.map(({ question, nextStep }, index) => (
              <NextQuestionButton
                key={`question__${index}`}
                onQuestionNextStep={_ => handleQuestionNextStep(nextStep)}
                {...{ question }}
              />
            ))}
          </div>
        )}
      </InteractiveVideo>
    </animated.div>
  )

  function handleQuestionNextStep({ type, video }) {
    if (type === NEXT_STEP_ACTIONS.FINISHED) {
      onVideoFlowFinished()
    } else if (type === NEXT_STEP_ACTIONS.VIDEO) {
      setCurrentVideoState(video)
      handleIsVideoEnding(false)
    }
  }

  function handleIsVideoEnding(isVideoEnding = false) {
    setIsVideoEnding(isVideoEnding)
  }
}

function NextQuestionButton({ question, onQuestionNextStep }) {
  return (
    <button onClick={onQuestionNextStep} data-x='link-to-next-step' className={styles.componentNextQuestionButton}>
      {question}
      <span className={cx(styles.arrowBox, styles.isRelativeToParent)}>
        <Icon icon={arrowIcon} />
      </span>
    </button>
  )
}

function SkillsCTA({ layoutClassName, onClickCtaButton }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSkillsCTA, layoutClassName)}>
      <HeadingXl h={2} title={__`skills-cta-title`} />
      <p className={styles.description}>{__`skills-cta-paragraph`}</p>
      <ButtonPrimary
        dataX='link-to-skills-tab'
        label={__`skills-cta-button-label`}
        onClick={onClickCtaButton}
        layoutClassName={styles.buttonLayout}
      />
    </div>
  )
}

async function handleMarkIntroductionSeen() {
  return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.markIntroductionSeen(), {
    method: 'POST',
  })
}
