import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { SkillsMatch } from '/features/pageOnly/skillsMatch/SkillsMatch'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

export function SkillsMatchApp({ onClose, layoutClassName = undefined }) {
  return (
    <QueryClientProvider client={queryClient}>
      <SkillsMatch {...{ onClose, layoutClassName }} />
    </QueryClientProvider>
  )
}
