import { useQuery } from '@tanstack/react-query'
import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'

import { LoaderWithBackdrop } from '/features/buildingBlocks/LoaderWithBackdrop'
import { Error } from '/features/pageOnly/skillsMatch/buildingBlocks/Error'
import { CloseButtonWhite } from '/features/pageOnly/skillsMatch/buildingBlocks/CloseButton'
import { InteractiveVideoTab } from '/features/pageOnly/skillsMatch/buildingBlocks/InteractiveVideoTab'
import { Skills } from '/features/pageOnly/skillsMatch/buildingBlocks/Skills'
import { ProofPoints } from '/features/pageOnly/skillsMatch/buildingBlocks/ProofPoints'
import { MySelection, useSkillsMatchUserSelection } from '/features/pageOnly/skillsMatch/buildingBlocks/MySelection'
import { ButtonPrimary } from '/features/buildingBlocks/Button'

import styles from './SkillsMatch.css'

const TABS = {
  introduction: 'introduction',
  skills: 'skills',
  proofPoints: 'proof-points'
}

const skillsMatchContentRenderers = {
  [TABS.introduction]: InteractiveVideoTab,
  [TABS.skills]: Skills,
  [TABS.proofPoints]: ProofPoints,
}

export function SkillsMatch({ onClose, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { hasSeenIntro, isFetching, isSuccess, isError } = useSkillsMatchData()

  return (
    <div className={cx(styles.component, layoutClassName)} data-style-context='light-alt'>
      <div className={styles.main}>
        <LoaderWithBackdrop layoutClassName={styles.loaderLayout} {...{ isFetching }} />

        {isError
          ? <Error title={__`skills-match-general-error`} layoutClassName={styles.errorLayout} />
          : isSuccess && <SkillsMatchContent layoutClassName={styles.contentLayout} {...{ onClose, hasSeenIntro }} />
        }
      </div>
    </div>
  )
}

function SkillsMatchContent({ onClose, hasSeenIntro, layoutClassName = undefined }) {
  const language = useLanguage()
  const { __ } = useTranslate()
  const { data: userSelection } = useSkillsMatchUserSelection({ language })
  const [activeTab, setActiveTab] = React.useState(hasSeenIntro ? TABS.skills : TABS.introduction)
  const ContentComponent = skillsMatchContentRenderers[activeTab]
  const showSideBar = activeTab !== TABS.introduction
  const hasSkills = Boolean(userSelection?.skills?.length)
  const hasProofPoints = Boolean(userSelection?.proofPoints?.length)
  const hasMadeSelection = hasSkills || hasProofPoints

  return (
    <div className={cx(styles.componentContent, showSideBar && styles.showSideBar, layoutClassName)}>
      <SkillsMatchNavigation
        onChangeTab={handleTabChange}
        tabIds={Object.values(TABS)}
        layoutClassName={styles.skillsMatchNavigationLayout}
        {...{ onClose, activeTab }}
      />

      <div className={styles.contentContainer}>
        <ContentComponent goToNextStep={handleGoToNextStep} />
      </div>

      {showSideBar && (
        <aside className={styles.aside} data-x='my-selection'>
          <MySelection layoutClassName={styles.mySelectionLayout} />

          <div className={styles.finishButtonContainer}>
            <p>{__`button-description`}</p>
            <ButtonPrimary
              onClick={onClose}
              dataX='close-modal'
              label={__`get-started`}
              disabled={!hasMadeSelection}
            />
          </div>
        </aside>
      )}
    </div>
  )

  function handleTabChange(tabId) {
    setActiveTab(tabId)
  }

  function handleGoToNextStep() {
    if (activeTab === TABS.introduction) setActiveTab(TABS.skills)
  }
}

function SkillsMatchNavigation({ onClose, tabIds, onChangeTab, activeTab, layoutClassName }) {
  return (
    <div className={cx(styles.componentNavigation, layoutClassName)}>
      <CloseButtonWhite onClick={onClose} layoutClassName={styles.closeButtonLayout} />
      <ul className={styles.tabNavigation}>
        {tabIds.map((tabId, i) => (
          <li key={i} className={styles.navListItem}>
            <SkillsMatchNavItem {...{ onChangeTab, tabId, activeTab }} />
          </li>
        ))}
      </ul>
    </div>
  )
}

function SkillsMatchNavItem({ onChangeTab, activeTab, tabId }) {
  const { __ } = useTranslate()

  return (
    <button
      onClick={() => onChangeTab(tabId)}
      className={cx(styles.componentNavItem, activeTab === tabId && styles.isActive)}
      data-x={`link-to-${tabId}`}
    >
      {__`tab-title-${tabId}`}
    </button>
  )
}

function useSkillsMatchData() {
  const reportError = useReportError()

  const { data, isSuccess, isError, isFetching } = useQuery({
    queryKey: [routeMap.api.v1.skillsMatch.introductionSeen()],
    queryFn,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })

  async function queryFn() {
    try {
      return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.introductionSeen(), {
        method: 'GET',
      })
    } catch (e) {
      reportError(e)
    }
  }

  return { hasSeenIntro: data?.introductionSeen, isSuccess, isError, isFetching }
}
