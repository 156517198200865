import { Loader } from '/features/buildingBlocks/Loader'

import styles from './LoaderWithBackdrop.css'

export function LoaderWithBackdrop({ isFetching, layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName, isFetching && styles.isFetching)}>
      {isFetching && <Loader layoutClassName={styles.loaderLayout} />}
    </div>
  )
}
