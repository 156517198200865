import ReactPlayer from 'react-player/lazy'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { trackInteraction } from '/analytics/user-interaction'
import { getVideoMetaDataProps, trackVideoMetadata, useProgressTracker } from '/analytics/video'

import { PlayButtonAndTitleLarge } from '/features/buildingBlocks/PlayButtonAndTitle'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './InteractiveVideo.css'

import playIcon from '/images/icons/play.raw.svg'
import pauseIcon from '/images/icons/pause.raw.svg'
import muteIcon from '/images/icons/mute.raw.svg'
import unMuteIcon from '/images/icons/unmute.raw.svg'

export function InteractiveVideo({ children, url, title, importedPoster = undefined, layoutClassName = undefined, onIsVideoEnding, onHasInteracted, hasFollowUpQuestions }) {
  const [playing, setPlaying] = React.useState(false)
  const [muted, setMuted] = React.useState()
  const [hasInteracted, setHasInteracted] = React.useState(false)
  const trackProgress = useProgressTracker({ title })
  const isMounted = useRenderOnMount()
  const videoRef = React.useRef(null)

  React.useEffect(
    () => {
      if (!hasInteracted) return
      setPlaying(true)
    },
    [hasInteracted, url]
  )

  React.useEffect(
    () => {
      if (muted) {
        trackInteraction({
          category: 'skillsmatch-portal',
          type: 'play',
          action: 'video muted',
        })
      }
    },
    [muted]
  )

  const playerConfig = {
    file: {
      attributes: {
        poster: importedPoster,
      }
    },
    vimeo: {
      playerOptions: {
        title: false,
        playsinline: true
      },
    },
  }

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {!hasInteracted && (
        <>
          <PlayButtonAndTitleLarge
            {...{ title, playing }}
            layoutClassName={styles.playButtonLayout}
            onClick={() => setPlaying(!playing)}
          />
          <div className={styles.overlay} />
        </>
      )}

      {isMounted && (
        <ReactPlayer
          width="100%"
          height="100%"
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onEnded={handleEnded}
          playsinline
          autoPlay
          ref={videoRef}
          config={playerConfig}
          // eslint-disable-next-line @kaliber/layout-class-name
          className={styles.player}
          {... { url, playing, muted }}
        />
      )}

      {hasInteracted && (
        <>
          <CustomControls
            layoutClassName={styles.customControlsLayout}
            onChangeMuted={handleChangeMuted}
            onChangePlaying={handleChangePlaying}
            {...{ playing, muted }}
          />
        </>
      )}
      <div className={styles.questionSlot}>
        {children}
      </div>
    </div>
  )

  function handleChangeMuted() {
    setMuted(x => !x)
  }

  function handleSeek(x) {
    videoRef.current.seekTo(x)
  }

  function handleEnded() {
    handleVideoTracking('video completed')
  }

  function handleChangePlaying() {
    if (playing) {
      handlePause()
    } else {
      handlePlay()
    }
  }

  function handlePlay() {
    if (hasReachedVideoEndingThreshold()) {
      handleSeek(0)
    }

    setPlaying(true)
    handleHasInteracted()
    onIsVideoEnding(false)
    handleVideoTracking('video started')
  }

  function handleHasInteracted() {
    setHasInteracted(true)
    onHasInteracted(true)
  }

  function handlePause() {
    setPlaying(false)
    handleVideoTracking('video paused')
  }

  function handleProgress({ played }) {
    trackProgress(played)

    if (!hasFollowUpQuestions) return

    if (hasReachedVideoEndingThreshold()) {
      onIsVideoEnding(true)
      handlePause()
    }
  }

  function hasReachedVideoEndingThreshold() {
    const endingThresholdInSeconds = hasFollowUpQuestions ? 4 : 0
    const playedSeconds = videoRef.current.getCurrentTime()
    const totalVideoDuration = videoRef.current.getDuration()

    return totalVideoDuration - playedSeconds <= endingThresholdInSeconds
  }

  function handleVideoTracking(action) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackVideoMetadata({
      category: 'skillsmatch-portal',
      type: 'play',
      action,
      video: getVideoMetaDataProps({ title, duration, progress })
    })
  }
}

function CustomControls({ layoutClassName, onChangeMuted, muted, onChangePlaying, playing }) {
  return (
    <div className={cx(styles.componentCustomControls, layoutClassName)}>
      <CustomControlButton
        activeIcon={pauseIcon}
        inActiveIcon={playIcon}
        onClick={onChangePlaying}
        active={playing}
        layoutClassName={styles.customControlButtonLayout}
        dataX={playing ? 'pause-video' : 'play-video'}
      />
      <CustomControlButton
        activeIcon={unMuteIcon}
        inActiveIcon={muteIcon}
        onClick={onChangeMuted}
        active={muted}
        layoutClassName={styles.customControlButtonLayout}
        dataX={muted ? 'unmute-video' : 'mute-video'}
      />
    </div>
  )
}

function CustomControlButton({ activeIcon, inActiveIcon, onClick, active, layoutClassName, dataX }) {
  return (
    <button
      className={cx(styles.componentCustomControlButton, layoutClassName, active && styles.isActive)}
      type='button'
      data-x={dataX}
      {...{ onClick }}
    >
      <Icon
        icon={active ? activeIcon : inActiveIcon}
        layoutClassName={styles.controlIconLayout}
      />
    </button>
  )
}
