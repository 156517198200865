import { useQuery, useQueryClient } from '@tanstack/react-query'
import isEqual from 'react-fast-compare'

import { routeMap } from '/routeMap'
import { useReportError } from '/machinery/ReportError'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'

import { LoaderWithBackdrop } from '/features/buildingBlocks/LoaderWithBackdrop'
import { Error } from '/features/pageOnly/skillsMatch/buildingBlocks/Error'
import { ExpanderHeading } from '/features/pageOnly/skillsMatch/buildingBlocks/ExpanderHeading'
import { useSkillsMatchUserSelection } from './MySelection'
import { SkillsMatchButtonRefresh } from '/features/pageOnly/skillsMatch/buildingBlocks/SkillsMatchButton'
import { SkillsWithSearchbar } from '/features/pageOnly/skillsMatch/buildingBlocks/SkillsWithSearchbar'
import { ListItemButton } from '/features/pageOnly/skillsMatch/buildingBlocks/ListItemButton'

import styles from './Skills.css'

export function Skills() {
  const { __ } = useTranslate()
  const language = useLanguage()

  const {
    suggestedSkills,
    availableSkills,
    isSuccess,
    isError,
    isFetching,
    refetch,
    canRefreshSuggestions
  } = useSkillsData({ language })

  return (
    <div className={styles.component}>
      <LoaderWithBackdrop layoutClassName={styles.loaderLayout} {...{ isFetching }} />
      {isError
        ? <Error title={__`skills-match-general-error`} layoutClassName={styles.errorLayout} />
        : isSuccess && (
          <div className={styles.listsWrapper}>
            <div className={styles.skillsContainer}>
              <SuggestedSkills
                options={suggestedSkills ?? {}}
                onRefresh={handleOnRefresh}
                showRefreshButton={canRefreshSuggestions}
              />
              <SkillsWithSearchbar
                options={availableSkills}
                layoutClassName={styles.searchBoxLayout}
              />
            </div>
          </div>
        )
      }
    </div>
  )

  function handleOnRefresh() {
    refetch()
  }
}

function SuggestedSkills({ options, onRefresh, showRefreshButton }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentSuggestedSkills}>
      <ExpanderHeading h='3' title={__`skills-match-skills-suggested`}>
        <List {...{ showRefreshButton, onRefresh, options }} />
      </ExpanderHeading>
    </div>
  )
}

function List({ options, onRefresh, showRefreshButton }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentList}>
      {Object.entries(options).map(([id, { label, isSelected }]) => (
        <ListItemButton key={id} {...{ id, label, isSelected }} />
      ))}
      {showRefreshButton && (
        <SkillsMatchButtonRefresh onClick={onRefresh} label={__`skills-match-button-label-suggestions`} />
      )}
    </div>
  )
}

function useSkillsData({ language }) {
  const queryClient = useQueryClient()
  const reportError = useReportError()
  const [lastFetchedUserSelection, setLastFetchedUserSelection] = React.useState(null)
  const { data: userSelection } = useSkillsMatchUserSelection({ language })
  const { data, isSuccess, isError, isFetching } = useQuery({
    queryKey: ['skillsData'],
    queryFn: () => fetchSkillsData({ language, reportError }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  React.useEffect(
    () => {
      if (lastFetchedUserSelection) return
      setLastFetchedUserSelection(userSelection)
    },
    [userSelection, lastFetchedUserSelection]
  )

  const canRefreshSuggestions = !isEqual(lastFetchedUserSelection, userSelection)
  const { suggestedSkills: suggestedSkillsRaw, availableSkills: availableSkillsRaw } = data ?? {}
  const selectedSkills = userSelection?.skills?.map(([id]) => id) ?? []

  const suggestedSkills = suggestedSkillsRaw && Object.fromEntries(
    Object.entries(suggestedSkillsRaw)
      .map(([id, data]) => (
        [id, ({ ...data, isSelected: selectedSkills.includes(id) })]
      ))
  )

  const availableSkills = availableSkillsRaw && Object.fromEntries(
    Object.entries(availableSkillsRaw)
      .sort((a, b) => a[1].label.localeCompare(b[1].label))
      .map(([id, data]) => (
        [id, ({ ...data, isSelected: selectedSkills.includes(id) })]
      ))
  )

  function refetch() {
    if (isFetching) return
    setLastFetchedUserSelection(userSelection)
    queryClient.invalidateQueries({ queryKey: ['skillsData'] })
  }

  return { suggestedSkills, availableSkills, isSuccess, isError, isFetching, refetch, canRefreshSuggestions }
}

async function fetchSkillsData({ language, reportError }) {
  try {
    return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.skillsData(), {
      method: 'POST',
      body: JSON.stringify({ language })
    })
  } catch (e) {
    reportError(e)
  }
}
