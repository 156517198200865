import { ExpandVertical } from '/features/buildingBlocks/Expand'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ExpanderHeading.css'

import chevronDown from '/images/icons/chevron-down.raw.svg'

export function ExpanderHeading({ title, h, children, initialStateIsCollapsed = undefined, disabled = undefined, count = undefined }) {
  const [isActive, setIsActive] = React.useState(true)

  React.useEffect(
    () => {
      if (disabled) return setIsActive(true)
      setIsActive(!initialStateIsCollapsed)
    },
    [initialStateIsCollapsed, disabled]
  )

  return (
    <div className={cx(styles.component, isActive && styles.isActive)}>
      <header className={styles.ExpanderHeader}>
        {disabled
          ? <HeadingSm {...{ title, h }} />
          : <Button {...{ title, h, isActive }} onClick={() => handleClick()} />
        }
        {count && <Counter layoutClassName={styles.counterLayout} {...{ count }} />}
      </header>
      <ExpandVertical expanded={isActive}>{children}</ExpandVertical>
    </div>
  )

  function handleClick() {
    setIsActive(!isActive)
  }
}

function Button({ title, h, isActive, onClick, layoutClassName = undefined }) {
  return (
    <button
      type='button'
      aria-expanded={isActive}
      className={cx(styles.componentButton, layoutClassName)}
      {...{ onClick }}
    >
      <HeadingSm {... { title, h }} />
      <div className={cx(styles.iconContainer, isActive && styles.isActive)}>
        <Icon icon={chevronDown} />
      </div>
    </button>
  )
}

function Counter({ count, layoutClassName }) {
  return (
    <span className={cx(styles.componentCounter, layoutClassName)}>
      {count}
    </span>
  )
}
