import { useTranslate, useLanguage } from '/machinery/I18n'

const NEXT_STEP_ACTIONS = {
  VIDEO: 'video',
  FINISHED: 'finished',
}

export function useVideoData() {
  const { __ } = useTranslate()
  const language = useLanguage()

  const data = {
    opening: {
      title: __`skills-video-a-title`,
      video: {
        sources: {
          nl: 'https://player.vimeo.com/progressive_redirect/playback/901067529/rendition/1080p/file.mp4?loc=external&signature=d0ad7777998e27ca424b1434861dc6f6c4a7d008ecf1cb6913bf7cc81f402809',
          en: 'https://player.vimeo.com/progressive_redirect/playback/903279535/rendition/1080p/file.mp4?loc=external&signature=6879b41bb536c20b164e6a15b8908dffa57db7bae942be230031c491c4da4eef',
        },
        poster: '/images/poster-janine-interactive-video.png'
      },
      questions: [
        {
          question: __`skills-question-how-does-this-work-exactly`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoB'
          },
        },
        {
          question: __`skills-question-whats-in-it-for-me`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoC'
          },
        },
      ],
    },
    videoB: {
      title: __`skills-question-how-does-this-work-exactly`,
      video: {
        sources: {
          nl: 'https://player.vimeo.com/progressive_redirect/playback/901067506/rendition/1080p/file.mp4?loc=external&signature=b0755b476132f4df6610aff246da2659bb110875dd2c1f8a63bb9387a0104509',
          en: 'https://player.vimeo.com/progressive_redirect/playback/903279443/rendition/1080p/file.mp4?loc=external&signature=634623fc2713addfcfa3f201f9c5e6aad1332b6dd05404484bb8f92028d7277b',
        },
        poster: '/images/poster-janine-interactive-video.png'
      },
      questions: [
        {
          question: __`skills-question-yes-continue-with-skills-match`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.FINISHED,
          },
        },
        {
          question: __`skills-question-whats-in-it-for-me`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoC'
          },
        },
        {
          question: __`skills-question-why-are-you-doing-this`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoD'
          },
        },
      ],
    },
    videoC: {
      title: __`skills-question-whats-in-it-for-me`,
      video: {
        sources: {
          nl: 'https://player.vimeo.com/progressive_redirect/playback/901067487/rendition/1080p/file.mp4?loc=external&signature=e739eb1d149cab6efb8fab5c2de4af626a337acfc8e9d42fad0871a50f0f0afb',
          en: 'https://player.vimeo.com/progressive_redirect/playback/903279500/rendition/1080p/file.mp4?loc=external&signature=35070989dabcafd8e8e07f6e4624ead5747ad1726404b42513a5824cb1dc25a2',
        },
        poster: '/images/poster-janine-interactive-video.png'
      },
      questions: [
        {
          question: __`skills-question-how-does-this-work-exactly`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoB'
          },
        },
        {
          question: __`skills-question-why-are-you-doing-this`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoD'
          },
        },
      ],
    },
    videoD: {
      title: __`skills-question-why-are-you-doing-this`,
      video: {
        sources: {
          nl: 'https://player.vimeo.com/progressive_redirect/playback/901067466/rendition/1080p/file.mp4?loc=external&signature=893fa503ba0c6ea7647681468a800078eb59c52e10d6b3df558fcb97fcf9ee9a',
          en: 'https://player.vimeo.com/progressive_redirect/playback/903279372/rendition/1080p/file.mp4?loc=external&signature=95e39dea41f3e80e9ad4b0f346327bd509869d935a1b55b7cb4f0fe5bc4ab177',
        },
        poster: '/images/poster-janine-interactive-video.png'
      },
      questions: [
        {
          question: __`skills-question-yes-continue-with-skills-match`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.FINISHED,
          },
        },
        {
          question: __`skills-question-no-thanks-i-skip-it`,
          nextStep: {
            type: NEXT_STEP_ACTIONS.VIDEO,
            video: 'videoE'
          },
        },
      ],
    },
    videoE: {
      title: __`skills-question-no-thanks-i-skip-it`,
      video: {
        sources: {
          nl: 'https://player.vimeo.com/progressive_redirect/playback/901067449/rendition/1080p/file.mp4?loc=external&signature=01ab30f5a481a723d0e64727303e4a60781b51f935fa5a77a9200ee0ad9ea80a',
          en: 'https://player.vimeo.com/progressive_redirect/playback/903279407/rendition/1080p/file.mp4?loc=external&signature=f64e3386c3f72f686ac2850ea318103a2ec95f094289929c55d8bdcf16a996b1',
        },
        poster: '/images/poster-janine-interactive-video.png'
      },
    },
  }

  const dataWithVideoSourcesForCurrentLanguage = Object.fromEntries(
    Object.entries(data).map(([id, { title, questions, video }]) => {
      const entry = {
        title,
        questions,
        video: {
          poster: video.poster,
          src: video?.sources?.[language] || video?.sources?.en
        }
      }

      return [id, entry]
    })
  )

  return { data: dataWithVideoSourcesForCurrentLanguage, NEXT_STEP_ACTIONS }
}
