import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './Error.css'

export function Error({ title, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <HeadingSm h={2} {...{ title }} />
    </div>
  )
}
