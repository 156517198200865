import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useReportError } from '/machinery/ReportError'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { trackInteraction } from '/analytics/user-interaction'

import { LoaderWithBackdrop } from '/features/buildingBlocks/LoaderWithBackdrop'
import { SkillsMatchButtonSelected } from '/features/pageOnly/skillsMatch/buildingBlocks/SkillsMatchButton'
import { ExpanderHeading } from '/features/pageOnly/skillsMatch/buildingBlocks/ExpanderHeading'

import mediaStyles from '/cssGlobal/media.css'
import styles from './MySelection.css'

export function MySelection({ layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const { data, isFetching, isSuccess, isError } = useSkillsMatchUserSelection({ language })
  const hasSkills = Boolean(data?.skills?.length)
  const hasProofPoints = Boolean(data?.proofPoints?.length)
  const hasCount = hasSkills || hasProofPoints
  const selectionCount = isSuccess && hasCount ? data?.skills?.length + data.proofPoints?.length : null

  React.useEffect(
    () => {
      isSuccess && pushToDataLayer({ user: { skillmatch_count: hasCount ? selectionCount : 0 } })
    },
    [selectionCount, hasCount, isSuccess]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <LoaderWithBackdrop layoutClassName={styles.loaderLayout} {...{ isFetching }} />

      {isError && <div>{__`skills-match-general-error`}</div>}

      {isSuccess && (
        <ExpanderHeading
          h='2'
          title={__`my-selection-title`}
          initialStateIsCollapsed={!isViewportMd}
          disabled={isViewportMd}
          count={selectionCount}
        >
          <div className={styles.selectionListsWrapper}>
            {hasCount && <RemoveMySelectionButton />}
            <h3 className={styles.selectionListTitle}>{__`my-selection-skills`}</h3>
            {hasSkills ?
              <SelectionList
                options={data?.skills}
                type='skill'
              /> : <p>{__`no-skills-selected`}</p>
            }
            <h3 className={styles.selectionListTitle}>{__`my-selection-proof-points`}</h3>
            {hasProofPoints ?
              <SelectionList
                options={data?.proofPoints}
                type='proofPoint'
              /> : <p>{__`no-proof-points-selected`}</p>
            }
          </div>
        </ExpanderHeading>
      )}
    </div>
  )
}

function SelectionList({ options, type }) {
  return (
    <div className={styles.componentSelectionList}>
      <div className={styles.items}>
        {options.map(([id, label]) => (
          <SelectionListButton key={id} {...{ id, label, type }} />
        ))}
      </div>
    </div>
  )
}

function SelectionListButton({ id, label, type }) {
  const { toggleUserSelection, isMutating } = useMySelectionMutations()

  return <SkillsMatchButtonSelected onClick={_ => handleOnClick(id)} isProcessing={isMutating} {...{ label }} />

  function handleOnClick(id) {
    toggleUserSelection({ type, id })
  }
}

function RemoveMySelectionButton() {
  const { removeUserSelection, isMutating } = useMySelectionMutations()
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      onClick={handleRemoveMySelection}
      disabled={isMutating}
      className={styles.componentRemoveMySelectionButton}
      aria-label={__`remove-all-skills-and-interests`}
      data-x='clear-all-skills-and-interests'
    >
      {__`skills-match-clear-selection`}
    </button>
  )

  function handleRemoveMySelection() {
    removeUserSelection()
    trackInteraction({
      category: 'skillsmatch-portal',
      type: 'click',
      action: 'clear-all-skills-and-interests'
    })
  }
}

export function useSkillsMatchUserSelection({ language }) {
  const reportError = useReportError()

  const { data, isSuccess, isError, isFetching } = useQuery({
    queryKey: ['userSelection'],
    queryFn: () => fetchUserSelection({ language, reportError }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return { data, isSuccess, isError, isFetching }
}

async function fetchUserSelection({ language, reportError }) {
  try {
    return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.userSelection(), {
      method: 'POST',
      body: JSON.stringify({ language })
    })
  } catch (e) {
    reportError(e)
  }
}

export function useMySelectionMutations() {
  const queryClient = useQueryClient()
  const language = useLanguage()

  const { mutate: toggleUserSelection, isPending: isMutatingToggle } = useMutation({
    mutationFn: handleToggleUserSelection,
    onError: reportError,
    onSuccess
  })

  const { mutate: removeUserSelection, isPending: isMutatingRemove } = useMutation({
    mutationFn: handleRemoveUserSelection,
    onError: reportError,
    onSuccess
  })

  const isMutating = isMutatingToggle || isMutatingRemove

  async function handleToggleUserSelection({ type, id }) {
    return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.toggleUserSelection(), {
      method: 'POST',
      body: JSON.stringify({ type, id, language })
    })
  }

  async function handleRemoveUserSelection() {
    return fetchWithResponseHandler(routeMap.api.v1.skillsMatch.removeUserSelection(), {
      method: 'POST',
    })
  }

  function onSuccess() {
    queryClient.invalidateQueries({ queryKey: ['userSelection'] })
  }

  return { toggleUserSelection, removeUserSelection, isMutating }
}
